.primary-background {
  background-color: #40535d;
}
/*for user profile*/
.img-shadow {
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}
.card-header #profile_img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: -60px;
  /* box-shadow: 0 5px 25px 0 #ef7852; */
  margin-top: -75px;
  background-color: #fff;
}
.for-user-profile .has-success.has-label:after,
.for-user-profile .has-danger.has-label:after {
  top: 33px;
  height: 5px;
}
.user-profile-card-style {
  position: relative;
  top: 8%;
}
/**/
/*---------*/
.react-tel-input .form-control {
  padding-left: 48px !important;
}
/*-----------*/
/*-------notification type icons style------*/
.icon-style {
  width: 25px;
  height: 25px;
}
/*------DIV TO PRINT(A4 SIZE)----------*/
/* .html2canvas-container {
  width: 3000px !important;
  height: 3000px !important;
} */
#divToPrint {
  page-break-after: always;
  /* display: none; */
  width: 210mm;
  height: 100%;
  margin: 30mm 45mm 30mm 45mm;
  /* width: 3000px !important;
  height: 3000px !important; */
}
/*----------------*/
#container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#content {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 25px;
  border: 1px solid #666;
}
/*---------*/
.countryCodeStyles {
  display: inline-flex;
}
.countryCodeStyles select {
  width: 25%;
  background-color: #dadada;
}
.countryCodeStyles select option {
  width: 25%;
}
.countryCodeStyles > .custom-select {
  height: 37px !important;
}
/*----------------------------------------react-table css--------------------------------*/
.raeactTablex .rt-th:nth-child(1n) {
  width: 20% !important;
}
.raeactTablex .rt-td:nth-child(1n) {
  width: 20% !important;
}
.raeactTablex .rt-th:nth-child(2n) {
  width: 10% !important;
}
.raeactTablex .rt-td:nth-child(2n) {
  width: 10% !important;
}
.raeactTablex .rt-th:nth-child(3n) {
  width: 22% !important;
}
.raeactTablex .rt-td:nth-child(3n) {
  width: 22% !important;
}
.raeactTablex .rt-th:nth-child(5n) {
  width: 10% !important;
}
.raeactTablex .rt-td:nth-child(5n) {
  width: 10% !important;
}
.raeactTablex .rt-th:nth-child(6n) {
  width: 8% !important;
}
.raeactTablex .rt-td:nth-child(6n) {
  width: 8% !important;
}
.rt-resizable-header {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  content: " ";
  color: #fa7a50;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
}
.widget-content {
  text-align: center;
}
.ReactTable .pagination-bottom {
  color: #40535d;
  font-weight: 600;
  text-align: center;
}
.ReactTable .pagination-bottom .-pagination .-previous .-btn {
  color: #fff;
  font-weight: 600;
  text-align: center;
  background-color: #40535d;
}
.ReactTable .pagination-bottom .-pagination .-next .-btn {
  color: #fff;
  font-weight: 600;
  text-align: center;
  background-color: #40535d;
}
.ReactTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #40535d;
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead.-headerGroups {
  display: none;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable input,
.ReactTable select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"]),
.ReactTable select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"])::-ms-expand,
.ReactTable select::-ms-expand {
  display: none;
}

.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}

.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px;
}

.ReactTable .select-wrap:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  -webkit-transition: none !important;
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*--------------------------------react-table css end here---------------------------*/
.form-input-styles {
  margin-bottom: 0px !important;
}
.background-style {
  background-color: #6c757d;
}
.margin-css {
  margin-left: 5rem;
}
.cursor-pointer {
  cursor: pointer;
}
.line-height-style {
  line-height: 2;
}
.logo-container {
  text-align: center;
}
.logo-container img {
  width: 50%;
}
.change-password-logo img {
  width: 40% !important;
  margin-bottom: 0px;
}
.margin-top-style {
  margin-top: 8.5vh;
}
.card-color {
  background-color: #40535d !important;
}
.logo-container-x img {
  width: 15%;
  text-align: center;
}
.custom-card {
  background: #ffffff21;
}
.footer-text {
  text-align: center;
  margin-top: 50px;
  color: #fff;
}
.footer-texts {
  text-align: center;
  color: #fff;
}
.footer-texts a {
  text-transform: none;
}
.conditions-link a {
  text-decoration: none;
}
.conditions-link a > a:hover {
  text-decoration: underline;
}
.font-size-css {
  font-size: 12px !important;
}
.section-image:after,
.full-page:after {
  background: none;
}
.custom-size {
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.c-color {
  color: #67a9ff;
}
.btn-cstm {
  position: relative;
  left: 30px;
  width: 342px;
}

.btn-lg {
  font-size: 1em;
  border-radius: 0.25rem;
  padding: 13px 0px;
}
.input-group.no-border .form-control:focus {
  border: none;
  background-color: #fff;
}
.full-page .full-page-content {
  padding-bottom: 20px;
  padding-top: 40px;
}
.card-margin-bottom .form-control {
  margin-bottom: 25px !important;
}
.custom-card .form-control {
  margin-bottom: 40px;
  background: #dadada;
  border-radius: 3px;
}
.user-email-id {
  margin-bottom: 21px !important;
}
.custom-card .form-control:focus {
  background: #ffffff;
}
/*for login page*/
.has-success.has-label:after,
.has-danger.has-label:after {
  top: 10px;
}
.cursor-none {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
/*for registration page*/
.for-registration-label .has-success.has-label:after,
.for-registration-label .has-danger.has-label:after {
  top: 10px;
}
.custom-card .input-group.no-border.form-control-lg .form-control {
  margin: 5px 0px;
}
.form-group.no-border.form-control-lg .form-control,
.input-group.no-border.form-control-lg .form-control {
  padding: 10px 15px;
}
.has-success:after,
.has-danger:after {
  font-weight: bold;
  font-size: 12px;
}
.input-group,
.form-group {
  width: 100%;
}
/*invitation modal css starts here*/
.modal-title-style {
  font-size: 20px;
}
.suggest-loading {
  background-color: #ffffff;
  background-image: url("http://loadinggif.com/images/image-selection/3.gif");
  background-size: 25px 25px;
  background-position: right center;
  background-repeat: no-repeat;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: 0px 0px 5px 0px #007bff;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.top-space {
  top: 80px !important;
}
.top-space-two {
  top: 80px !important;
}
.top-space-two .modal-content {
  width: 100%;
  margin: auto;
}
.react-autosuggest__container > input {
  width: 100%;
  height: 40px;
}
.invite-title {
  margin-left: 34px;
  font-size: 20px;
}
.user-invite-input {
  border-radius: 0 !important;
  height: 40px !important;
}
/*ends here*/
/*side bar css starts here*/
.margin-right-css {
  margin-right: 0px !important;
}
.sidebar {
  box-shadow: unset;
}
.sidebar .logo:after,
.off-canvas-sidebar .logo:after {
  content: none;
}
.sidebar .logo .simple-text,
.off-canvas-sidebar .logo .simple-text {
  text-transform: none !important;
  padding: 0.5rem 0;
  display: block;
  /* white-space: nowrap; */
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  /* overflow: hidden; */
}
.logout-style:hover {
  border-radius: 13px !important;
  text-transform: none !important;
  font-size: 13.5px !important;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar-mini .sidebar .sidebar-wrapper > .nav li > a span {
  -webkit-transform: translate3d(-25px, 0, 0);
  transform: translate3d(-25px, 0, 0);
  opacity: 0;
  display: none;
}
.sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li > a span {
  -webkit-transform: translate3d(-25px, 0, 0);
  transform: translate3d(-25px, 0, 0);
  opacity: 1;
  display: inline-block;
  margin-left: 25px;
}
.logo-img {
  text-align: center;
  margin-right: 6%;
  height: 60px;
}
.logo-width {
  width: 50%;
  max-width: none;
}
.top-title {
  font-weight: bold !important;
  color: #fa7a50 !important;
  margin-top: 4px !important;
}
.sidebar .nav li.active > a,
.off-canvas-sidebar .nav li.active > a {
  background-color: #fa7a50;
  font-weight: 600;
  font-size: 15px !important;
}
.sidebar .nav li.active > a:not([data-toggle="collapse"]) {
  color: #fff;
}
.sideBar-routes {
  border-radius: 13px !important;
}
.sidebar .nav li > a {
  border-radius: 13px !important;
  text-transform: none;
  font-size: 13.5px !important;
}
.sidebar-color {
  background-color: #40535d !important;
}
.sidebar:after,
.sidebar[data-color="black"]:after,
.off-canvas-sidebar:after,
.off-canvas-sidebar[data-color="black"]:after {
  background: none !important;
}
.sidebar-footer {
  height: 48px;
  width: 65%;
  position: absolute;
  bottom: 0;
  margin-left: 43px;
}
.navbar-toggler {
  background-color: #fa7a50;
}
@media (min-width: 991px) {
  .sidebar-mini .sidebar .sidebar-footer {
    opacity: 0;
  }
  .sidebar-mini .sidebar:hover .sidebar-footer {
    opacity: 1;
  }
  /* .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 25px;
        height: 4px;
        border-radius: 15px;
        background: #000000;
    } */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .sidebar-btn-landscape {
    display: block !important;
  }
}

/*ends here*/
.breadcrumb-style .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;
  margin-bottom: 0 !important;
  list-style: none;
  background-color: transparent;
  border-radius: 0 !important;
}
.card-shadow {
  cursor: pointer;
  margin-bottom: 0px !important;
  min-height: 222px;
}
.card-shadow2 {
  margin-bottom: 0px !important;
  min-height: 222px;
}
.card-header-background {
  background-color: #40535d !important;
}
.icon-color {
  color: #40535d !important;
}
.card-category {
  color: #ffffff !important;
  padding: 0px 0px 12px 0px;
  margin-bottom: 0px !important;
}
/*issues-table-styles*/
.card-data-body {
  padding: 10px 15px 10px 15px !important;
}
.card-data-body .table-responsive {
  padding-bottom: 0px !important;
}
.table-responsive .table {
  max-width: none;
}
.issues-table > tbody > tr > td {
  padding: 12px 7px;
}
/*issues-table-styles-end*/
.table-data {
  margin-bottom: 0px !important;
}
.table-data td {
  border-top: none;
  border-bottom: 1px solid #e9ecef;
  /* background: #e9ecef; */
}
.table-header-width-one {
  width: 27%;
}
.table-header-width-two {
  width: 16%;
}
.margin-top-max {
  margin-top: 8vh !important;
}
.margin-top-max-two {
  margin-top: 15vh !important;
}
.main-panel {
  background-color: #fff !important;
}
.search-bar-css {
  background-color: #f2f2f2 !important;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border: #aaa !important;
  /* box-shadow: 0 1px 15px 1px rgba(39,39,39,.1)!important; */
  /* width: 100%!important; */
}
.shadow-css {
  background-color: #f2f2f2 !important;
  border: #aaa !important;
  /* box-shadow: 0 1px 15px 1px rgba(39,39,39,.1)!important; */
}
.icon-css {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.list-style li {
  list-style: none;
}
.padding-one {
  padding: 25px 5px 25px 17px;
}
.padding-two {
  padding: 15px 5px 15px 5px;
}
.add-member-icon {
  width: 14px;
  padding-bottom: 2px;
}
.prop-icons {
  margin-left: 4px;
  width: 23px;
}
/**table styles*/
/* .members-table-styles {
    border-collapse:separate; 
    border-spacing:0 15px; 
} */
/* .team-actions-style:hover {
    animation-name: rotate; 
    animation-duration: 2s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #FA7A50;
}
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
} */
.table th,
.table td {
  border-top: none !important;
}
.table-header-row {
  background-color: #40535d;
  box-shadow: 0 0px 0px 1px rgba(39, 39, 39, 0.1) !important;
  color: #fff !important;
  padding: 20px 15px 20px 15px;
  line-height: 2;
  font-size: 15px;
}
.table-data-rows {
  background-color: #ffffff;
  box-shadow: 0 0px 0px 1px rgba(39, 39, 39, 0.1) !important;
  line-height: 2;
  cursor: pointer;
  font-size: 15px;
}
.table-data-rows-two {
  background-color: #ffffff;
  box-shadow: 0 0px 0px 1px rgba(39, 39, 39, 0.1) !important;
  line-height: 2;
  font-size: 15px;
}
.description-width {
  width: 40%;
}
/*ends here*/
/**loader css*/
.loader-s {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 68, 68, 0.281);
  z-index: 1030;
}
.browser-screen-loading-content {
  text-align: center;
  height: 2em;
  max-width: 100%;
  position: absolute;
  top: 165px;
  right: 0;
  bottom: 3em;
  left: 0;
  margin: auto;
}
.loading-dots {
  margin-left: -1.5em;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-animation: loading-dots-fadein 0.5s linear forwards;
  -moz-animation: loading-dots-fadein 0.5s linear forwards;
  -o-animation: loading-dots-fadein 0.5s linear forwards;
  -ms-animation: loading-dots-fadein 0.5s linear forwards;
  animation: loading-dots-fadein 0.5s linear forwards;
}
.loading-dots i {
  width: 0.65em;
  height: 0.65em;
  display: inline-block;
  vertical-align: middle;
  background: #e0e0e0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 0.125em;
  -webkit-animation: loading-dots-middle-dots 0.5s linear infinite;
  -moz-animation: loading-dots-middle-dots 0.5s linear infinite;
  -o-animation: loading-dots-middle-dots 0.5s linear infinite;
  -ms-animation: loading-dots-middle-dots 0.5s linear infinite;
  animation: loading-dots-middle-dots 0.5s linear infinite;
}
.loading-dots.dark-gray i {
  background: #fa7a50;
}
.loading-dots i:first-child {
  -webkit-animation: loading-dots-first-dot 0.5s infinite;
  -moz-animation: loading-dots-first-dot 0.5s linear infinite;
  -o-animation: loading-dots-first-dot 0.5s linear infinite;
  -ms-animation: loading-dots-first-dot 0.5s linear infinite;
  animation: loading-dots-first-dot 0.5s linear infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translate(-1em);
  -moz-transform: translate(-1em);
  -o-transform: translate(-1em);
  -ms-transform: translate(-1em);
  transform: translate(-1em);
}
.loading-dots i:last-child {
  -webkit-animation: loading-dots-last-dot 0.5s linear infinite;
  -moz-animation: loading-dots-last-dot 0.5s linear infinite;
  -o-animation: loading-dots-last-dot 0.5s linear infinite;
  -ms-animation: loading-dots-last-dot 0.5s linear infinite;
  animation: loading-dots-last-dot 0.5s linear infinite;
}

@-moz-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-moz-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

@-moz-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}
@-webkit-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}
@-o-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}
@keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    -moz-transform: translate(1em);
    -o-transform: translate(1em);
    -ms-transform: translate(1em);
    transform: translate(1em);
  }
}

@-moz-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    -moz-transform: translate(2em);
    -o-transform: translate(2em);
    -ms-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

/*create team modal css starts here*/
.team-input-style {
  height: 42px !important;
  /* background: #dadada; */
  border-radius: 3px;
}
.textarea-styles {
  max-width: 100%;
  max-height: 80px;
  padding: 10px 10px 0 0;
  border: 1px solid #dadada !important;
  /* border-bottom: 1px solid #E3E3E3; */
  border-radius: 5px !important;
  line-height: 2;
  overflow-y: hidden;
  /* background: #dadada; */
}
.create-team-title {
  font-size: 16px;
  background-color: #40535d !important;
  color: #ffffff;
  padding: 15px 0px 15px 15px !important;
}
.modal-content .create-team-title button {
  top: 1px !important;
  color: #fff !important;
}
/*end here*/
/*teams dashboard starts*/
.switch-style-css {
  position: relative;
  top: 12px;
}
.dummy-class .dropdown > a {
  display: -webkit-box;
}
.bootstrap-switch-on .bootstrap-switch-label {
  background: #fa7a50 !important;
}
.actions-icon-style {
  font-size: 25px;
}
.status-styles {
  position: relative;
  bottom: 4px;
}
.remove-paddings {
  padding-top: 0px !important;
}
/**/
/*issues details page*/
.name-badge-styles {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  font-size: 15px;
  padding: 13px 7px;
}
/*/
/*------------------table loader css------------*/
.table-tr-loader {
  position: relative;
}
.no-data-found-style {
  position: absolute;
  top: 100px;
  left: 45%;
}
.loader-inside-tr {
  position: absolute;
  top: 100px;
  left: 45%;
  width: 40px;
}
.loader-inside-dashboards {
  position: absolute;
  top: 300px;
  left: 45%;
  width: 40px;
  color: #000000;
  z-index: 1;
}
.loader-inside-roles {
  position: absolute;
  top: 220px;
  left: 45%;
  width: 40px;
  color: #000000;
  z-index: 1;
}
/*create rule modal css*/
.form-check {
  padding-left: 0% !important;
}
.form-check .form-check-label {
  padding-left: 17px;
}
/* used for checkbbnox design */
.position-style {
  position: unset !important;
}
.checkmark-two {
  left: 0% !important;
  right: 85%;
}
.ccheck {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #7f7f7f;
}
.ccheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 85%;
  height: 25px;
  width: 25px;
  /* background-color: #f96332; */
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #7f7f7f;
}
/* On mouse-over, add a grey background color */
.ccheck:hover input ~ .checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.ccheck input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #f96332;
  color: #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.ccheck input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.ccheck .checkmark:after {
  left: 8px;
  top: 2px;
  width: 7px;
  height: 15px;
  border: solid #f96332;
  border-width: 0 1.1px 1.1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.position-style-class-one {
  position: relative;
}
.position-style-class-two {
  position: absolute;
  top: 23%;
}
.position-style-class-three {
  position: relative;
  left: 20px;
}
/*search with list example*/
.custom-select {
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  height: 50px;
  border-color: transparent;
}
.select-search-box {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}
.select-search-box__options {
  padding-left: 0px;
}
.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.select-search-box::after {
  font-family: fontawesome;
  content: "\f078";
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  line-height: 50px;
  color: #222f3e;
  z-index: 1;
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: "\f002";
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  outline: none;
  /* font-size: 16px; */
  padding: 0 20px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  line-height: 50px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
  line-height: 1;
  border: solid 1px #dadada;
  border-radius: 3px;
  box-shadow: none;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #f96332;
  color: #fff;
  border-top-color: #f96332;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #f96332;
  color: #fff;
  border-top-color: #f96332;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
/*ends here*/
.remove-select-styles .custom-select {
  box-shadow: none;
  height: 41px;
  border-color: #e3e3e3;
  /* max-height: 200px;
  overflow-y: auto; */
}
.spinner-parent {
  position: relative;
}
.spinner-child {
  position: absolute;
  top: 18px;
  right: -23px;
}
/*partner and customer status switch style*/
.switch-styles .bootstrap-switch {
  margin-bottom: 0px !important;
}
/*agent download certificate spinner icon style*/
.icon-position-style {
  position: absolute;
  margin-top: 9px;
  margin-left: 5px;
}
/*issues design styles*/
.card-style {
  background-color: #f2f2f2 !important;
  border: #aaa;
  border-radius: 5px;
  padding: 20px;
  box-shadow: none;
}

.box-style {
  border: 1px solid #e7e7e7;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  margin-bottom: 20px;
}
.font-size-style {
  font-size: 12px;
}
.user-css-styles {
  height: 25px;
  max-width: 25px !important;
  position: relative;
  right: 4px;
}
.issue-details-modal-style {
  max-width: 60%;
  overflow: hidden;
}
.issues-comments-styles {
  height: 80%;
  overflow-x: hidden;
  padding-left: 10px;
}
/**for scroll bar hide**/
.comment-scrollbar {
  overflow: hidden;
}
.comment-scrollbar .issues-comments-styles {
  height: 250px;
  overflow: auto;
  margin: 15px -300px 15px 0px;
  padding-right: 300px;
  cursor: pointer;
}
.issue-dropdown-css {
  background-color: #fff4f2;
  width: 75%;
  border-radius: 3px;
}
.issue-dropdown-css-two {
  background-color: #e2e2e2;
  width: 75%;
  border-radius: 3px;
}
.margin-padding-for-p > p {
  padding: 0 !important;
  margin: 0 !important;
}
/*need to remove*/
/* .delete-button-style {
  border-radius: 30px;
  padding: 10px 12px;
} */
.table-data-width {
  width: 50%;
}
.cursor-pointer > input {
  cursor: pointer !important;
  background-color: #fbfafa !important;
}
/*media quires*/
@media only screen and (max-width: 768px) {
  /*media query for IPad */
  .position-style-class-two {
    position: absolute;
    top: 23%;
    right: 10px;
  }
  .issue-details-modal-style {
    max-width: 70%;
  }
  .font-size-style {
    font-size: 10px;
  }
  .card-style {
    padding: 8px;
  }
  .user-css-styles {
    right: 15px;
  }
  .priority-icon-style {
    position: relative;
    right: 9px;
  }
  .mobile-display-style {
    display: -webkit-box;
  }
  .table-data-width {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .issue-dropdown-css {
    width: 50%;
  }
  .issue-details-modal-style {
    max-width: 100%;
  }
  .sidebar-mini .sidebar .sidebar-wrapper > .nav li > a span {
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    opacity: 1;
    display: inline-block;
    margin-left: 25px;
  }
  .position-style-class-one {
    position: unset;
  }
  .position-style-class-two {
    position: unset;
  }
  .table-data-width {
    width: 50%;
  }
}
@media only screen and (max-width: 375px) {
  /*media query for Iphone X,6/7/8 */
  .margin-bottom-style {
    margin-bottom: 50px !important;
  }
  .create-team-button {
    display: none;
  }
  .mobile-button {
    margin-left: 17px;
  }
  .margin-top-max-two {
    margin-top: 8vh !important;
  }
  .table-responsive .table {
    max-width: none;
  }
  .table-data-width {
    width: 50%;
  }
}
@media only screen and (max-width: 414px) {
  /*media query for Iphone 6/7/8 plus,pixel 2 and pixel 2plus */
  .margin-bottom-style {
    margin-bottom: 50px !important;
  }
  .mobile-display-style {
    display: -webkit-box;
  }
  .issue-dropdown-css {
    width: 50% !important;
  }
  .issue-dropdown-css-two {
    width: 100%;
  }
  .create-team-button {
    display: none;
  }
  .mobile-button {
    margin-left: 17px;
  }
  .mobile-button-top-style {
    margin-top: 25px;
  }
  .margin-top-max-two {
    margin-top: 3vh !important;
  }
  .margin-top-max {
    margin-top: 5vh !important;
  }
  .table-responsive .table {
    max-width: none;
  }
  .mobile-style-css {
    position: relative;
  }
  .mobile-switch-style-css {
    position: relative;
    right: 170px;
    top: 44px;
  }
  .table-data-width {
    width: 50%;
  }
}

.react-tel-input input[type="tel"] {
  width: 100% !important;
  background-color: #dadada !important;
  border-radius: 2px !important;
}

/* Dashboard CSS starting ....  */
/* Header css Starting  */
.dadhboard-header{
  display: flex;
  min-height: 80px;  
  background-color: #F8F8F8;
}

.dadhboard-header-div{
  margin: auto;
  margin-left: 20px !important;
}

.dadhboard-header-input-div{
  margin: auto;
  padding-top: 11px;
  margin-right: 0px !important;
}


.dadhboard-header-div-text{
  display: contents;
  font-weight: 500;
  font-size: 24px;
  color: #FA7A50;
}

.dadhboard-header-input{
  border-radius: 0px;
}
/* Header CSS end  */
/* Team Dashboard Role CSS starting  */
  
.team-dashboard-role{
  margin-top: 40px;
  margin-bottom: 0px;
}

.team-dashboard-role-card-height
{
   min-height: 90px;
   border: 1px solid #DFDFDF;
   box-shadow: none;
}

.team-dashboard-role-card-body{
  margin: auto;
  width:100%;
  padding: 15px 0px 10px 15px !important;
}

.team-dashboard-role-card-body-image{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.team-dashboard-role-card-body-title{
  font-size: 14px;
  color:  #E15426;
  font-weight: 500;
}
.team-dashboard-role-card-body-sub-title{
  font-size: 24px;
  color:  #E15426;
  font-weight: bold;
}
/* Team Dashboard Role CSS End */

/* Team Dahshboard Table Css Starting */

.team-dashboard-Table{
  margin-top: 20px;
}

.team-dashboard-table-heading-card{
  border: 1px solid #DFDFDF;
  margin-bottom: -0px !important;
  box-shadow: none;
  display: flex;
}

.team-dashboard-table-footer-card{
  border: 1px solid #DFDFDF !important;
  border-top: 0px solid!important;
  border-bottom: 1px solid  #DFDFDF  !important;
  box-shadow: none;
  height: 45px;
  margin-bottom: 0px;
  display: flex;
}
.team-dashboard-Table-border{
  border: 1px solid #DFDFDF;
  border-top: 0px solid !important;
  margin-bottom: 0px; 
}

.team-dashboard-table-header{
  display: flex;
  position: relative;
  place-items: center;
}

.team-dashboard-table-header-button{
  position: absolute;
  right: 20px;
}

.team-dashboard-table-header-text{
   margin: 10px;
}

.team-dashboard-table-header-button-style{
  width:77px;
  height: 29px;
  padding: 0px;
  margin: 0px;
  background-color: #E7591C;
  color: #fff;
  font-size: 16px;
}

.organization-dashboard-table-header-button-style{
  width:71px;
  height: 29px;
  padding: 0px;
  margin: 0px;
  background-color: #E7591C;
  color: #fff;
  font-size: 16px;
  float: right;
}

.organization-dashboard-table-header-button-style-inactive{
  width:71px;
  height: 29px;
  padding: 0px;
  margin: 0px;   
  background-color: #79819D;
  color: #fff;
  font-size: 16px;
  float: right;
}

.team-dashboard-table-header-button-style-button{
  width: 48px;
  height: 29px;
  padding: 0px;
  margin: 0px;   
  background-color: #FA7A50;
  color: #fff;
  font-size: 16px;
}
.team-dashboard-table-heading-border{
  border: 1px solid #DFDFDF;
}
.team-dashboard-table-heading-border{
  font-weight: 500;
  font-size: 14px;
  color: #66849F;
}

.team-dashboard-table-heading-border > th{
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #66849F !important;
  text-align: center;
}

.team-dashboard-table-heading-border-first{
  text-align: left !important;
}

.team-dashboard-table-body{
 
  width: auto;
  height: 400px;
  overflow-y: scroll;
}

.team-dashboard-table-body > tr {
  border-bottom: 1px solid #DFDFDF;
}

.team-dashboard-table-body > tr > td{
  font-size: 16px;
  color: #175BC0;
  text-align: center !important;
}

.table-heading-center {
  text-align: center !important;
}

.team-dashboard-table-body > tr > th{
  font-size: 16px;
  color: #175BC0;
  font-weight: normal;
}


.team-dashboard-table-recent-alerts >tr > td{
  padding: 10px 7px !important;
}
.team-dashboard-table-recent-alerts-title{
  padding-left: 5px;
  font-size: 12px;
  margin: 0px;
}
.team-dashboard-table-recent-alerts-instance{
  margin: 0px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 500;
}
.team-dashboard-table-recent-alerts-time{
  margin: 0px;
  font-size: 10px;
  color: #8C8C8C;
  padding-left: 5px;
}
.team-dashboard-table-recent-alerts-activity-title{
  padding-left: 5px;
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
  color: #175BC0;
}
.team-dashboard-table-recent-alerts-activity-instance{
  margin: 0px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #175BC0;
}

.instance-name {
  color: #175BC0;
}

.team-dashboard-table-recent-alerts-activity-time{
  margin: 0px;
  font-size: 12px;
  color: #8C8C8C;
  padding-left: 5px;
}

.team-dashboard-table-recent-alerts-high {
  border-left: 3px solid #CC2323;
}

.team-dashboard-table-recent-alerts-medium {
  border-left: 3px solid #FF8A00;
}

.team-dashboard-table-recent-alerts-closed {
  border-left: 3px solid  #F05349;
}
.team-dashboard-table-recent-alerts-low {
  border-left: 3px solid #1E7FF1;
}
 

.dashboard-view-all-previous {
  background-color: transparent !important;
  border: 1px solid #D0D0D0 !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  margin-left: 10.2px !important; 
  
}

.team-dashboard-table-header-prev{
  position: absolute;
  right: 20px;
  top: 6px;
}

.team-dashboard-table-header-footer-card{
  background-color:  #fff;
}
.team-dashboard-table-header-footer-alerts{
  display : flex;
  justify-content: center;
  place-items: center;
  margin-top: auto;
}
.team-dashboard-table-header-footer-alerts-high{
  margin-left: 30px;
  color: #CC2323;
  font-size: 20px;
} 

.team-dashboard-table-footer-card-alerts{
  border: 1px solid #DFDFDF !important;
  border-top: 0px solid !important;
  border-bottom: 1px solid #DFDFDF !important;
  box-shadow: none;
  height: 45px;
  display: flex;
}

.team-dashboard-card-header{
  display: flex;
  place-items: center !important;
  
}

.team-dashboard-graph-header-text{
  padding-left: 10px  !important;
  margin: 0px;
  display: contents;
} 


.team-dashboard-pie-chart{
  margin-top: 40px;
}


.card-items-inline-form > div > p {
  margin-right: 10px;
  font-size: 12px;
  color: #96A4B1;
}

.dashboard-assigned{
  color: #FB7C52 !important;
  margin-left: 15px;
}

.dashboard-closed{
  color: #4060D0 !important;
  margin-left: 15px;
}

.dashboard-unasigned{
  color: #3497DF;
  margin-left: 15px;
}
.dahboard-alert-high{
  margin-right: 5px;
  
}
.dahboard-alert-medium{
  margin-right: 5px;
  color: #FF8A00 !important;
}
.dahboard-alert-low{
  margin-right: 5px;
}

.team-dashboard-piechart{
  margin-top: 40px;
}

.team-dahboard-barchart{
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Team Dashboard Table CSS End */
/* Team dashboard line graph Starting */
.team-dashboard-line-graph{
  margin-top: 40px;
}
.team-dashbaord-activity{
  display: flex;
  justify-content: flex-end;
}

.team-dashbaord-activity-button{
  width: 95px;
  height: 36px;
  padding: 0px;
  margin: 0px;   
  background-color: rgba(73, 130, 240, 0.1);;
  color: #4982F0;
  font-size: 14px;
}

.team-dashbaord-activity-button-closed{
  width: 95px;
  height: 36px;
  padding: 0px;
  margin: 0px;   
  background-color: rgba(240, 83, 73, 0.1);
  color: #F05349;
  font-size: 14px;
}

.table-wrapper-scroll-y {
  display: block;
}

.my-custom-scrollbar {
  position: relative;
  height: 450px;
  overflow: auto;
  }

.column-size-padding{
  padding: 7px 7px  !important;
}

/* Team dashbord line graph ending */
.team-dashboard-table-button{
  display: flex;
  justify-content: flex-end;
  padding: 7px 7px  !important;
}

.empty-row-height{
  height: 47px !important;
}

/* Dashbaord CSS End  */

.empty-row-width{
  height: 49px !important;
}

.loader-center{
display: grid;
place-items: center;
height: 80vh;
}

.scrollbar{
  position: fixed;
}

.fixed_header{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 0px !important;
}

.team-dashboard-table-body{
  height: 450px !important;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 100px;
  border: 1px solid #DFDFDF;
}

.fixed_header thead tr {
   display: block;
   border: 1px solid #DFDFDF;
   overflow: auto;
}
 
.fixed_header th, .fixed_header td {
  
  width: 200px;
}

.team-dashboard-table-body-custom{
  height: 475px !important;
}

/* Perspective css */

.perspective-head-card {
  display: flex;
  justify-content: space-between;
}
.perspective-button {
  text-align: end;
}

.instance-lable {
  text-align: left;
  color: #40535d;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.instance-options {
  text-align: left;
}


/* Toggle icon styles */
.toggle-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 5px; /* Adjust spacing */
  margin-top: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transition: transform 0.3s ease; /* Smooth transition for icon rotation */
}

/* Icon for the open state */
.toggle-icon.open {
  transform: rotate(-135deg);
}

/* Icon for the closed state */
.toggle-icon.closed {
  transform: rotate(45deg);
}

.perspective-modal {
  height: 80%;
  max-width: 60%;
}
.perspective-modal .modal-content {
  height: 100%;
  overflow: auto;
}
.perspective-create-button {
  text-align: end;
    margin-right: 12%;
}


/* notification css */
.notification-head-card {
  justify-content: space-between;
}

.notification-edit {
  text-align: end;
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  margin-left: 8px;
}

.un-read-notification {
    background-color: #d9d9d9; 
    border: 1px solid #e7e7e7;
    color: #333;
    border-radius: 5px;
    padding: 20px;
    box-shadow: none;
    margin-bottom: 20px;
}

.notification-user-icon {
  text-align: center;
}






@media only screen and (max-width: 375px) {
  /*media query for Iphone X,6/7/8 */

  .btn-group > .btn, .btn-group-vertical > .btn {
    padding: 0.7rem 1rem !important;
}
  

}

@media only screen and (max-width: 440px) {
  /*media query for Iphone 6/7/8 plus,pixel 2 and pixel 2plus */
  .dashboard-instance {
    margin-bottom: 2rem;
  }

  .dadhboard-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    padding: 8px 0px;
  }

  .dadhboard-header-div {
    text-align: center;
    margin-right: 0;
  }

  .dadhboard-header-input-div{
    margin-left: 22px !important;
  }
  .btn-group > .btn, .btn-group-vertical > .btn {
    padding: 0.8rem 1.2rem;
}
.perspective-button{
  display: none;
}
.perspective-modal {
  max-width: 100%;
}
.notification-user-icon {
  text-align: end;
  padding-right: 0;
}

.ReactTable .-pagination {
  font-size: 12px;
}

.mobile-hidden {
  display: none;
}

}